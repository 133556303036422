import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import Button from '/imports/core/ui/atoms/Button';
import Label from '/imports/core/ui/atoms/Label';
import { RTLLanguages } from '/imports/generator/api/constants';
import intlHook from '/imports/core/api/useIntl';

export const LEVELS = [
  { title: 'NOVICE', translationSlug: 'generator.skills.level_novice', value: '1' },
  { title: 'BEGINNER', translationSlug: 'generator.skills.level_beginner', value: '2' },
  { title: 'SKILLFUL', translationSlug: 'generator.skills.level_skillful', value: '3' },
  { title: 'EXPERIENCED', translationSlug: 'generator.skills.level_experienced', value: '4' },
  { title: 'EXPERT', translationSlug: 'generator.skills.expert', value: '5' },
];

export const SKILL_LEVEL_LIST = LEVELS.map((l) => l.title);

const RTL_LEVELS = [...LEVELS].reverse();

const COLORS_MAP = {
  NOVICE: 'danger',
  BEGINNER: 'warning',
  SKILLFUL: 'yellow',
  EXPERIENCED: 'lightGreen',
  EXPERT: 'success',
};

const SkillLevel = (props) => {
  const { t, locale } = intlHook();
  const [level, setLevel] = useState(() => {
    return props.value || 'NOVICE';
  });
  const firstLevel = LEVELS.find((lev) => lev.title === level).value === '1';
  const lastLevel = LEVELS.find((lev) => lev.title === level).value === '5';
  const { language } = props;
  const { translationSlug } = LEVELS.find((lev) => lev.title === level);

  const handleChangeLevel = (level) => {
    setLevel(level);
    props.onChange({ target: { value: level } });
  };

  const handleChange = (e) => {
    if (e.target.value === '0') return;
    const title = LEVELS.find((e) => e.value === event.target.value).title;
    this.setLevel(title);
  };

  return (
    <Fragment>
      <StyledLabel language={language}>
        {t('generator.skills.level')} -{' '}
        <Level language={language} level={level}>
          {t(translationSlug).toLocaleLowerCase(locale)}
        </Level>
      </StyledLabel>

      <LevelInput level={level} firstLevel={firstLevel} lastLevel={lastLevel}>
        {(RTLLanguages.includes(locale) ? RTL_LEVELS : LEVELS).map((l, i) => (
          <LevelButton key={`level-${i}`} level={l.title} onClick={() => handleChangeLevel(l.title)} />
        ))}
      </LevelInput>
    </Fragment>
  );
};

SkillLevel.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  language: PropTypes.string,
};

const StyledLabel = styled(Label)`
  margin-bottom: 3px;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 14px;
  letter-spacing: 0.25px;
  color: #7e8592;
`;

const Level = styled.span`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  text-transform: capitalize;
  color: ${(p) => p.theme.colors[COLORS_MAP[p.level]]};
`;

const LevelInput = styled.div`
  position: relative;
  display: flex;
  height: 48px;
  border-radius: ${({ theme }) => (theme.designV2 ? ' 8px' : '3px')};
  background-color: ${(p) => rgba(p.theme.colors[COLORS_MAP[p.level]], 0.1)};
  margin-right: 1px;

  &:after {
    content: '';
    position: absolute;
    width: calc(${100 / LEVELS.length}% + 1px);
    height: 100%;
    border-radius: ${({ theme }) => (theme.designV2 ? '0' : '3px')};
    ${({ firstLevel, lastLevel, theme }) =>
      (firstLevel || lastLevel) &&
      theme.designV2 &&
      css`
        border-radius: ${firstLevel ? '8px 0 0 8px' : '0 8px 8px 0'};
      `}
    top: 0;
    // transition: ${(p) => p.theme.transitions.medium};
    left: ${(p) => LEVELS.findIndex((level) => level.title === p.level) * 20}%;
    will-change: left;
    background: ${(p) => p.theme.colors[COLORS_MAP[p.level]]};
  }

  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      flex-direction: row-reverse;

      &:after {
        left: unset;
        right: ${(p) => LEVELS.findIndex((level) => level.title === p.level) * 20}%;
        margin-right: -1px;

        ${({ firstLevel, lastLevel, theme }) =>
          (firstLevel || lastLevel) &&
          theme.designV2 &&
          css`
            border-radius: ${firstLevel ? '0 8px 8px 0 ' : '8px 0 0 8px'};
          `}
      }
    `}
`;

const LevelButton = styled((p) => <Button unstyled {...p} />)`
  position: relative;
  width: ${100 / LEVELS.length}%;
  height: 100%;
  border-radius: ${(p) => p.theme.general.borderRadius};

  &:hover {
    background-color: ${(p) => rgba(p.theme.colors[COLORS_MAP[p.level]], 0.1)};
  }

  &:not(:last-of-type) {
    &:after {
      content: '';
      position: absolute;
      right: -1px;
      top: 33.33%;
      height: 33.33%;
      width: 1px;
      background: ${(p) => rgba(p.theme.colors[COLORS_MAP[p.level]], 0.5)};
      ${({ theme }) =>
        theme.max('xs')`
          background-color: #90cfad;
      `}
    }
  }
`;

export default SkillLevel;
