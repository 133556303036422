import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import styled, { css } from 'styled-components';
import { withRouter } from 'next/router';

import Button from '/imports/core/ui/atoms/Button';
import { displayName } from '/imports/generator/api/helpers';
import Flex from '/imports/core/ui/atoms/Flex';
import { freeAccessForYear, isPaidUser, logout, getJobTrackRoute, staticFile } from '/lib/helpers';
import { Push } from '/components/Link';
import { ResponsiveConsumer } from '/imports/core/api/responsiveContext';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import { withIntl } from '/imports/core/api/useIntl';
import { withTracking } from '/imports/core/hooks/useTracking';

@withTracking
@withIntl
@withAccount
@withRouter
class UserDropdown extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object,
    userLoading: PropTypes.bool,
  };

  state = {
    expanded: false,
    checkoutSeen: undefined,
    currentUser: this.props.currentUser,
  };

  componentDidMount() {
    const { checkoutSeen } = this.state;

    if (checkoutSeen !== undefined) return;

    this.setState({
      checkoutSeen: localStorage.getItem('resumedone:checkout-seen'),
    });
  }

  componentDidUpdate(prevProps) {
    const { currentUser, userLoading } = this.props;
    const { userLoading: previousUserLoading } = prevProps;

    if (userLoading !== previousUserLoading) {
      this.setState({
        currentUser: { ...currentUser },
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.windowClick);
  }

  windowClick = () => {
    this.setState({ expanded: false });
    window.removeEventListener('click', this.windowClick);
  };

  handleClick = (e) => {
    e.stopPropagation();
    this.setState(
      (prevState) => ({ expanded: !prevState.expanded }),
      () => {
        if (this.state.expanded) {
          window.addEventListener('click', this.windowClick);
        } else {
          window.removeEventListener('click', this.windowClick);
        }
      },
    );
  };

  goToCheckout = () => {
    const {
      router: { asPath },
      locale,
    } = this.props;
    const queryString = {
      from: asPath,
    };
    Push(`/checkout?${qs.stringify(queryString)}`, locale, '/checkout');
  };

  logout = () => {
    const { resetTracking, trackEvent } = this.props;
    localStorage.removeItem('isCancelSubscriptionPopupHide');
    localStorage.removeItem('isLeadGenerated');
    logout(null, this.props.locale, { resetTracking, trackEvent });
  };

  handleNavigate = (account) => {
    const { isJobTrack, locale } = this.props;
    if (account) {
      if (isJobTrack) {
        const query = { template: 'account' };
        return Push(`/app?${qs.stringify(query)}`, locale, '/app/account');
      } else return Push('/account', locale);
    } else return Push('/contact-us', locale);
  };

  render() {
    const { expanded, checkoutSeen } = this.state;
    const { currentUser: { firstName, lastName, photo, role, freeAccessUntil } = {}, t, locale } = this.props;
    const isAdmin = role === 'admin';
    const isPaid = isPaidUser(role) || freeAccessForYear(freeAccessUntil);
    const { href, as } = getJobTrackRoute(this.props.host, 'resumes');
    return (
      <ResponsiveConsumer>
        {({ isMobile }) => (
          <DropdownCont $expanded={expanded}>
            <Button link onClick={this.handleClick}>
              <Avatar data-testid="user-photo" image={photo || staticFile('img/default_user_avatar.jpg')} />
              <TopName>
                {displayName(firstName, lastName, t('user'))}
                {isAdmin && <Badge>admin</Badge>}
              </TopName>
            </Button>
            <DropdownMenu onClick={this.handleClick}>
              <DropdownMenuInner>
                <Name>{displayName(firstName, lastName, t('user'))}</Name>
                <Flex direction="column" justifyContent="flex-start">
                  <DashboardButton link onClick={() => Push(href, locale, as)}>
                    {t('header.dashboard')}
                  </DashboardButton>
                  {!isPaid && checkoutSeen && (
                    <StyledButton link onClick={this.goToCheckout}>
                      {t('header.get_premium')}
                    </StyledButton>
                  )}
                  <StyledButton link onClick={() => this.handleNavigate(true)}>
                    {t('header.account_settings')}
                  </StyledButton>
                  <StyledButton link onClick={() => this.handleNavigate(false)}>
                    {t('header.contact_us')}
                  </StyledButton>
                  <StyledButton onClick={this.logout} link>
                    {t('header.log_out')}
                  </StyledButton>
                </Flex>
              </DropdownMenuInner>
            </DropdownMenu>
          </DropdownCont>
        )}
      </ResponsiveConsumer>
    );
  }
}

const DropdownCont = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 20px 0;
  &:hover {
    > div {
      transform: scaleY(1);
      opacity: 1;
    }
  }
  > div {
    transform: scaleY(${(p) => (p.$expanded ? '1' : '0')});
    opacity: ${(p) => (p.$expanded ? '1' : '0')};
  }
  > button {
    font-size: 32px;
    display: flex;
    align-items: center;
    padding-right: 8px;
    ${({ theme: { isRTL } }) =>
      isRTL &&
      css`
        padding-right: 0;
        padding-left: 8px;
      `}
    position: relative;
    &:hover {
      p {
        color: ${(p) => p.theme.colors.primary};
      }
      i {
        color: ${(p) => p.theme.colors.primary};
      }
    }
  }
  > button:before {
    content: '';
    position: absolute;
    top: 22px;

    ${({ theme: { isRTL } }) =>
      isRTL
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `};
    border: 4px solid transparent;
    border-bottom-width: 0;
    border-top-color: var(--black);
  }
  ${(p) => p.theme.max('md')`
    position: static;
  `}
  ${(p) => p.theme.max('lg')`
    padding: 20px 0;
    > button:before {
      top: 18px;
    }
  `}
`;

const Name = styled.p`
  margin-top: 0;
  margin-bottom: 30px;
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.5px;
  ${(p) => p.theme.min('md')`
    display: none;
  `}
`;

const TopName = styled.p`
  margin-right: 10px;
  color: var(--black);
  font-size: 13px;
  line-height: 23px;
  font-weight: 600;
  letter-spacing: 0.06em;
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  ${({ theme: { isRTL, max } }) => css`
    ${isRTL &&
    css`
      margin-right: 0;
      margin-left: 10px;
    `}
    ${max('lg')`
      display: none;
    `}
  `}
`;

const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
  // transition: ${(p) => p.theme.transitions.fast};
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;
  padding: 0;
  z-index: 10;
  padding-left: 20px;
  padding-top: 65px;
  background-color: transparent;
  ${({ theme: { max, isRTL } }) => max('md')`
    width: 100%;
    border-radius: 0;
    padding-left: 0;
    ${
      isRTL &&
      css`
        padding-right: 0;
      `
    }
    > button {
      display: block;
      margin-bottom: 20px !important;
    }
  `}
  button {
    display: block;
    padding: 15px 20px;
    transition: background-color 0.2s;
    width: 100%;
    margin: 0;
    text-align: left;
    color: var(--black);
    font-size: 13px;
    line-height: 23px;
    font-weight: 600;
    letter-spacing: 0.06em;
    font-family: ${({ theme }) => theme.font.family.websiteRegular};
    &:hover {
      background-color: rgba(34, 116, 176, 0.1);
    }
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      left: 0;
      right: unset;
      padding-left: 0;
      padding-right: 20px;
    `}
`;

const DropdownMenuInner = styled.div`
  box-shadow:
    rgba(207, 214, 230, 0.7) 0px 14px 16px -10px,
    rgba(207, 214, 230, 0.12) 0px 20px 40px -8px;
  background-color: #fdfefe;
  ${(p) => p.theme.max('md')`
    padding: ${p.theme.general.mobilePadding};
  `}
`;

const Avatar = styled.div`
  width: 37px;
  height: 37px;
  margin-right: 15px;
  border-radius: 50%;
  background: #f0f0f2 50% / cover no-repeat;
  background-image: url(${(p) => p.image});
  ${({ theme: { max, isRTL } }) => css`
    ${isRTL &&
    css`
      margin-right: 0;
      margin-left: 15px;
    `}
    ${max('lg')`
      margin-right: 10px;
      ${
        isRTL &&
        css`
          margin-right: 0;
          margin-left: 10px;
        `
      }
    `}
  `}
`;

const DashboardButton = styled(Button)`
  display: block !important;
  ${({ theme: { isRTL, min } }) => css`
    ${min('md')`
      display: none !important;
    `}
    ${isRTL &&
    css`
      display: flex !important;
      direction: rtl !important;
    `}
  `}
`;

const StyledButton = styled(Button)`
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      display: flex !important;
      direction: rtl !important;
      text-align: right !important;
    `}
`;
const Badge = styled.small`
  margin-left: 0.75em;
  padding: 0.25em 1em;
  border-radius: 500px;
  background-color: #e91e63;
  color: white;
  font-weight: bold;
`;

export default UserDropdown;
