import { TEMPLATES } from '/imports/generator/api/constants';
import { isFontUpdate } from '/lib/helpers';

export const getBasicSettings = (template, language, websiteLocale) => {
  const templateData = TEMPLATES.find((t) => t.id === template) || {};
  if (templateData?.redirectTemplate && templateData.redirectTemplate != '') {
    template = templateData?.redirectTemplate;
  }
  let defaultSettings = {
    template: template || 'budapest',
    color: templateData?.defaultColor || 'blue',
    status: 'start',
    language,
  };

  const withArabicFont = websiteLocale === 'ar';
  const isUpdate = isFontUpdate(language);

  if (isUpdate) defaultSettings.headingFont = 'robotoMono';
  if (template === 'atsresume') {
    defaultSettings.headingFont = 'helvetica';
    defaultSettings.contentFont = 'openSans';
  }
  if (withArabicFont) {
    defaultSettings.headingFont = 'tajawal';
    defaultSettings.contentFont = 'tajawal';
  }
  return {
    settings: defaultSettings,
    isSAResume: true,
  };
};
