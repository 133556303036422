import dynamic from 'next/dynamic';

import TemplatesSkeleton from '/imports/pdf/core/ui/skeleton/TemplatesSkeleton';

const Config = {
  loading: () => <TemplatesSkeleton />,
};

const BudapestCoverLetter = dynamic(() => import('/imports/pdf/budapest/ui/pages/BudapestCoverLetter'), Config);
const BudapestResume = dynamic(() => import('/imports/pdf/budapest/ui/pages/BudapestResume'), Config);
const BudapestV2Resume = dynamic(() => import('/imports/pdf/budapest-v2/ui/pages/BudapestResume'), Config);
const SABudapest = dynamic(() => import('/imports/pdf/budapest-v3/ui/pages/BudapestResume'), Config);
const ATSResume = dynamic(() => import('/imports/pdf/ATSresume/ui/Pages/ATSResume'), Config);
const ChicagoCoverLetter = dynamic(() => import('/imports/pdf/chicago/ui/pages/ChicagoCoverLetter'), Config);
const ChicagoResume = dynamic(() => import('/imports/pdf/chicago/ui/pages/ChicagoResume'), Config);
const KievCoverLetter = dynamic(() => import('/imports/pdf/kiev/ui/pages/KievCoverLetter'), Config);
const KievResume = dynamic(() => import('/imports/pdf/kiev/ui/pages/KievResume'), Config);
const MonteCarloCoverLetter = dynamic(() => import('/imports/pdf/monte-carlo/ui/pages/MonteCarloCoverLetter'), Config);
const MonteCarloResume = dynamic(() => import('/imports/pdf/monte-carlo/ui/pages/MonteCarloResume'), Config);
const PerthCoverLetter = dynamic(() => import('/imports/pdf/perth/ui/pages/PerthCoverLetter'), Config);
const PerthResume = dynamic(() => import('/imports/pdf/perth/ui/pages/PerthResume'), Config);
const Prague = dynamic(() => import('/imports/pdf/prague/ui/pages/PragueTemplate'), Config);
const RigaCoverLetter = dynamic(() => import('/imports/pdf/riga/ui/pages/RigaCoverLetter'), Config);
const RigaResume = dynamic(() => import('/imports/pdf/riga/ui/pages/RigaResume'), Config);
const RotterdamResume = dynamic(() => import('/imports/pdf/rotterdam/ui/pages/RotterdamResume'), Config);
const SF = dynamic(() => import('/imports/pdf/sf/ui/pages/SFTemplate'), Config);
const ShanghaiResume = dynamic(() => import('/imports/pdf/shanghai/ui/pages/ShanghaiResume'), Config);
const Sydney = dynamic(() => import('/imports/pdf/sydney/ui/pages/SydneyTemplate'), Config);
const VladivostokResume = dynamic(() => import('/imports/pdf/vladivostok/ui/pages/VladivostokResume'), Config);
const BruneiResume = dynamic(() => import('/imports/pdf/brunei/ui/pages/BruneiResume'), Config);
export default {
  budapest: {
    resume: BudapestResume,
    coverletter: BudapestCoverLetter,
  },
  'budapest-v2': {
    resume: BudapestV2Resume,
  },
  'budapest-v3': {
    resume: SABudapest,
  },
  sydney: {
    resume: Sydney,
  },
  chicago: {
    resume: ChicagoResume,
    coverletter: ChicagoCoverLetter,
  },
  prague: {
    resume: Prague,
  },
  riga: {
    resume: RigaResume,
    coverletter: RigaCoverLetter,
  },
  sf: {
    resume: SF,
  },
  montecarlo: {
    resume: MonteCarloResume,
    coverletter: MonteCarloCoverLetter,
  },
  kiev: {
    resume: KievResume,
    coverletter: KievCoverLetter,
  },
  perth: {
    resume: PerthResume,
    coverletter: PerthCoverLetter,
  },
  vladivostok: {
    resume: VladivostokResume,
  },
  shanghai: {
    resume: ShanghaiResume,
  },
  rotterdam: {
    resume: RotterdamResume,
  },
  atsresume: {
    resume: ATSResume,
  },
  brunei: {
    resume: BruneiResume,
  },
};
