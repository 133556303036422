import PropTypes from 'prop-types';

export const BLOCK_ITEMS_PROPTYPES_SCHEMA = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      animationKey: PropTypes.string,
      fields: PropTypes.object,
      id: PropTypes.string,
      order: PropTypes.number,
    }),
  ),
};

export const BLOCKS_PROPTYPES_SCHEMA = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      animationKey: PropTypes.string,
      fixedOrder: PropTypes.number,
      fixedPosition: PropTypes.boolean,
      hideLevel: PropTypes.boolean,
      hideReferences: PropTypes.boolean,
      id: PropTypes.string,
      ...BLOCK_ITEMS_PROPTYPES_SCHEMA,
      order: PropTypes.number,
      position: PropTypes.array,
      required: PropTypes.bool,
      title: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
};

export const SETTINGS_PROPTYPES_SCHEMA = {
  settings: PropTypes.shape({
    color: PropTypes.string,
    contentFont: PropTypes.string,
    fontSizeIncrementer: PropTypes.number,
    headingFont: PropTypes.string,
    language: PropTypes.string,
    status: PropTypes.string,
    template: PropTypes.string,
  }).isRequired,
};

export const DETAILS_PROPTYPES_SCHEMA = {
  details: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    postalCode: PropTypes.string,
    professionalSummary: PropTypes.string,
    socialLinks: PropTypes.string,
    title: PropTypes.string,
    userPic: PropTypes.string,
  }),
};

export const SOURCE_PROPTYPES_SCHEMA = {
  source: PropTypes.shape({
    author: PropTypes.string,
    ...BLOCKS_PROPTYPES_SCHEMA,
    createdAt: PropTypes.string,
    currentStep: PropTypes.string,
    ...DETAILS_PROPTYPES_SCHEMA,
    dupesCount: PropTypes.number,
    hasWebsite: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    ...SETTINGS_PROPTYPES_SCHEMA,
    slug: PropTypes.object,
    steps: PropTypes.array,
    updatedAt: PropTypes.string,
    updatesCount: PropTypes.number,
  }).isRequired,
};

export const RESUME_PROPTYPES_SCHEMA = {
  gender: PropTypes.string,
  id: PropTypes.string,
  onDragEnd: PropTypes.func,
  scale: PropTypes.number,
  setHeight: PropTypes.func,
  ...SOURCE_PROPTYPES_SCHEMA,
  variant: PropTypes.string,
  width: PropTypes.number,
  isRenameSectionTitle: PropTypes.bool,
};
