import { DropdownContent, ChevronContainer, DropdownMenu, DropdownButton } from '../Dropdown';
import React, { useState, useRef, useEffect } from 'react';
import { InputWrapper } from '/imports/core/ui/atoms/DropdownCustom';
import { InputStyle } from '/imports/core/ui/atoms/Input';
import styled from 'styled-components';
import intlHook from 'imports/core/api/useIntl';
import { Chevron } from '/imports/generator/ui/assets';
import PropTypes from 'prop-types';

//DropdownAutocomplete reusable components
function DropdownAutocomplete({ options, onSelect, placeholder, defaultValue, style }) {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const inputRef = useRef(null);
  const dropdownContentRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const { t } = intlHook();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        !dropdownMenuRef.current.contains(event.target) &&
        !inputRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setFilteredOptions(options);
    setInputValue(options.find((v) => v.value === defaultValue)?.title);
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);

    if (event.target.value.trim() !== '') {
      const filtered = options.filter((option) =>
        option.title.toLowerCase().includes(event.target.value.toLowerCase()),
      );
      if (filtered.length === 0) {
        setFilteredOptions(options);
      } else {
        setFilteredOptions(filtered);
      }
    } else {
      setFilteredOptions(options);
    }
    setIsOpen(true);
  };

  const handleOptionClick = (option) => {
    setInputValue(option.title);
    setIsOpen(false);
    onSelect(option);
  };

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <DropdownContent className="dropdown-autocomplete" ref={dropdownContentRef} style={style}>
        <InputWrapper id="input-wrapper">
          <Input
            type="text"
            placeholder={placeholder}
            value={inputValue}
            onChange={handleInputChange}
            onClick={handleDropdownToggle}
            ref={inputRef}
          />
          <ChevronContainer style={{ cursor: 'pointer' }} onClick={handleDropdownToggle}>
            {isOpen ? <Chevron.Up /> : <Chevron.Down />}
          </ChevronContainer>
        </InputWrapper>

        <DropdownMenu
          onClick={(e) => {
            e.stopPropagation();
          }}
          expanded={isOpen}
          className="dropdown-menu"
          id={`dropdown-menu-${name}`}
          ref={dropdownMenuRef}
        >
          {filteredOptions.length > 0 &&
            filteredOptions.map((option, index) => (
              <DropdownButton
                key={index}
                onClick={() => handleOptionClick(option)}
                selected={option?.title === inputValue}
                id={`translate-resume-value-${option.value}`}
              >
                {option.titleSlug && t(option.titleSlug) ? t(option.titleSlug) : option.title}
              </DropdownButton>
            ))}
        </DropdownMenu>
      </DropdownContent>
    </>
  );
}

export default DropdownAutocomplete;

DropdownAutocomplete.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
};
const Input = styled.input`
  ${InputStyle}
`;
