import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';

export default function HideMonthExperiment(props) {
  return (
    <MarvelExperiment name="exp_experience_hide_month">
      <MarvelVariant name="control">{props.children}</MarvelVariant>
      <MarvelVariant name="with_hide_month_checkbox">{props.children}</MarvelVariant>
    </MarvelExperiment>
  );
}
