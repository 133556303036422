import React, { Fragment } from 'react';
import pdfBoilerplate from './pdfBoilerplate';
import ReactDOMServer from 'react-dom/server';
import { resetServerContext } from 'react-beautiful-dnd';
import { ServerStyleSheet, ThemeProvider } from 'styled-components';

import { A4_HEIGHT, A4_WIDTH } from '/imports/pdf/core/api/constants';
import { getDefaultLanguage } from '/lib/helpers';
import GlobalStyles from '/styles/general';
import { IntlProvider } from '/imports/core/api/useIntl';
import PDFViewer from '/imports/pdf/core/ui/components/PDFViewer';
import theme from '/styles/theme';
import WebflowResumeCss from '/styles/WebflowResumeCss';

// For the templates with the background (gray sidebar etc), we need to set the height
// where templateHeight % A4_HEIGHT === 0, so that there is no ugly cuts in the generated pdf
const getA4PrintHeight = (printHeight) => {
  const rest = printHeight % A4_HEIGHT;
  if (!rest) return printHeight;
  return printHeight - rest + A4_HEIGHT;
};

const BORDER_WIDTH = 40;

// We are using 794px width on the UI, but A4 is 595. That is the division
const DPI_SWITCH_KOEFF = 595 / 794;

// These templates will take full printHeight
const FULL_BG_TEMPLATES = [
  'budapest',
  'chicago',
  'riga',
  'sydney',
  'sf',
  'kiev',
  'montecarlo',
  'prague',
  'vladivostok',
  'rotterdam',
  'atsresume',
];

const MARGIN_HIDE_ON_A4 = ['brunei'];
/**
 * Renders a HTML document from a resume or cover letter.
 *
 * @param {Object} source - Resume/Cover Letter JSON
 * @param {Number} height - The height of the preview document (HTML offset height).
 * @param {String} onePage - OnePage or not
 * @param {String} locale - Language of the resume
 * @param {String} isCoverLetter - CoverLetter or Resume
 * @returns {Promise<String>} - HTML document
 */
export const renderMarkup = async function (
  source,
  height,
  onePage,
  locale,
  isCoverLetter,
  gender,
  host,
  isWebflow,
  isSwapable,
  fromBuilder,
  isBudapestV2TagsSpacing = false,
  isBudapestDesignActive = false,
  middleContentLayout,
  mainColumnSize,
  nameSize,
  titleSize,
  paragraphSpacing,
  generalFontSize,
  sideBarContentLayout,
  isDesignWithSocialLink,
) {
  const sheet = new ServerStyleSheet();

  // Templates with backgrounds have a bit different behavior
  const withBackground = FULL_BG_TEMPLATES.includes(source.settings.template);
  const withBackgroundBrunei = !onePage && MARGIN_HIDE_ON_A4.includes(source.settings.template);
  // For templates with borders we need to subtract the borders total height to get a correct pages count
  const borderOffset = withBackground ? 0 : Math.ceil(height / A4_HEIGHT) * (BORDER_WIDTH * 2);

  // Calculate the document height with respect to the DPI conversion and borders
  let printHeight = Math.ceil(getA4PrintHeight(height) * DPI_SWITCH_KOEFF - borderOffset);

  // Extend the document height to the bottom of the last page,
  // to ensure the backgrounds/borders are full-height.
  // Start by calculating the number of pages.
  let numberOfPages = Math.max(1, Math.floor(height / A4_HEIGHT));

  // If there's a remainder, another page is needed.
  if (height > A4_HEIGHT && height % A4_HEIGHT !== 0) {
    numberOfPages += 1;
  }

  // If the document height doesn't extend to the full height of pages,
  // Then extend it down to the end of the last page.
  if (printHeight < numberOfPages * A4_HEIGHT) {
    printHeight = numberOfPages * A4_HEIGHT;
  }

  // pass value of printHeight if printHeight is less than A4_HEIGHT
  const isPrintHeight = (onePage && printHeight <= A4_HEIGHT) || !onePage;
  resetServerContext();
  // Render template to string with styles attached
  const view = ReactDOMServer.renderToString(
    sheet.collectStyles(
      <ThemeProvider theme={{ ...theme, isBudapestV2TagsSpacing }}>
        <IntlProvider value={locale || getDefaultLanguage(host)}>
          <Fragment>
            {isWebflow && !fromBuilder ? <WebflowResumeCss /> : <GlobalStyles />}
            <PDFViewer
              isSwapable={isSwapable}
              source={source}
              width={A4_WIDTH}
              withNegativeMargin={!withBackground}
              withBackgroundBrunei={!withBackgroundBrunei}
              isCoverLetter={isCoverLetter}
              variant={`control_${source.settings.template}`}
              currentUser={{ gender }}
              isPdf
              host={host}
              isBudapestDesignActive={isBudapestDesignActive}
              middleContentLayout={middleContentLayout}
              mainColumnSize={mainColumnSize}
              nameSize={nameSize}
              titleSize={titleSize}
              paragraphSpacing={paragraphSpacing}
              generalFontSize={generalFontSize}
              sideBarContentLayout={sideBarContentLayout}
              isDesignWithSocialLink={isDesignWithSocialLink}
            />
          </Fragment>
        </IntlProvider>
      </ThemeProvider>,
    ),
  );

  const markup = pdfBoilerplate(view, sheet.getStyleTags());
  sheet.seal();

  return markup;
};
