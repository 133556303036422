import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';
import { useRouter } from 'next/router';

import { useResponsive } from '/imports/core/api/responsiveContext';

export default function RenderUndoRedoTextareaEditorExp({ children }) {
  const { query } = useRouter();
  const { isMobile } = useResponsive();
  if (!['experience', 'education', 'summary', 'finish'].includes(query.step) || isMobile) return children;
  return (
    <MarvelExperiment name="exp_undoredo_textarea_editor">
      <MarvelVariant name="control">{children}</MarvelVariant>
      <MarvelVariant name="with_undo_redo">{children}</MarvelVariant>
    </MarvelExperiment>
  );
}
