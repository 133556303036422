import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { CheckIcon } from '/imports/core/ui/assets';
import useTracking from 'imports/core/hooks/useTracking';

const Checkbox = (props) => {
  const checked = props.checked || false;
  const width = props.width || '18px';
  const height = props.height || '18px';
  const borderRadius = props.borderRadius || '0';
  const color = props.color || '#1688fe';
  const { wizard, isDisabled, customCss } = props;
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (props.id === 'showHideMonth') {
      trackEvent('hide_month_checkbox', {
        value: checked ? 'checked' : 'unchecked',
      });
    }
  }, [props.checked]);

  return (
    <CheckboxCont
      checked={checked}
      width={width}
      height={height}
      wizard={wizard}
      customCss={customCss}
      borderRadius={borderRadius}
    >
      <Input {...props} type="checkbox" disabled={isDisabled} />
      {checked && <CheckIcon color={color} />}
    </CheckboxCont>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  borderRadius: PropTypes.number,
  color: PropTypes.string,
  wizard: PropTypes.string,
  isDisabled: PropTypes.bool,
  customCss: PropTypes.string,
};

const CheckboxCont = styled.div`
  position: relative;
  display: inline-block;
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  border: 1px solid ${(p) => p.theme.colors.gray.regular};
  border-radius: ${(p) => p.borderRadius};
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;

  ${({ wizard }) =>
    wizard &&
    css`
      border: solid 2px #e6e6ff;
      background-color: #fff;
    `}

  ${(p) => p.theme.max('md')`
    ${(p) =>
      !p.wizard &&
      css`
        width: 32px;
        height: 32px;
      `}
  `}

  svg {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 0px;
  }

  ${(props) => props.customCss}
`;

const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed !important;
    `}
`;

export default Checkbox;
