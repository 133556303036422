import PhotoDragAndDrop from './PhotoDragAndDrop';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PhotoCrop from '/imports/core/ui/atoms/PhotoCrop.js';

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const PhotoEdit = (props) => {
  const { cropSize = 200 } = props;
  const [image, setImage] = useState(null);

  const onChange = async ({
    target: {
      files: [file],
    },
  }) => {
    const readAndCropImage = async (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (event) => {
          const image = new Image();
          image.src = event.target.result;

          image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const maxSize = cropSize + 280;

            let width = image.width;
            let height = image.height;

            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
            } else {
              if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
              }
            }

            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(image, 0, 0, width, height);
            canvas.toBlob(resolve, file.type);
          };
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    };

    try {
      const croppedBlob = await readAndCropImage(file);
      const croppedBase64 = await toBase64(croppedBlob);
      setImage(croppedBase64);
    } catch (error) {
      console.error(error);
    }
  };

  return !image ? (
    <PhotoDragAndDrop onChange={onChange} />
  ) : (
    <PhotoCrop onChange={onChange} onSave={props.onSave} image={image} loading={props.loading} cropSize={cropSize} />
  );
};

PhotoEdit.propTypes = {
  onSave: PropTypes.func,
  cropSize: PropTypes.number,
  loading: PropTypes.bool,
};

export default PhotoEdit;
