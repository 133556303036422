import styled, { css } from 'styled-components';

const SocialLinkIcon = () => {
  return (
    <SvgIcon width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="16.9128"
        height="16.9128"
        rx="8.45638"
        transform="matrix(1 0 0 -1 0.761719 16.9922)"
        fill="#414141"
      />
      <path
        d="M7.82774 6.23835C6.68848 7.41699 6.93709 9.37297 8.2421 10.2394C8.28509 10.268 8.3423 10.2623 8.37924 10.2263C8.65397 9.95784 8.88639 9.69784 9.0899 9.36717C9.12104 9.31658 9.10167 9.25093 9.04943 9.22264C8.85038 9.1148 8.65231 8.91261 8.54081 8.69903L8.54067 8.69911C8.4071 8.43316 8.36163 8.13504 8.43234 7.82715C8.43242 7.82717 8.43249 7.82719 8.43257 7.82719C8.51394 7.43301 8.93715 7.06633 9.26039 6.72722C9.25971 6.72699 9.25905 6.72674 9.25837 6.72652L10.4694 5.49055C10.9521 4.998 11.7459 4.99394 12.2336 5.48154C12.7261 5.96411 12.7343 6.76192 12.2517 7.25445L11.5181 8.00873C11.4842 8.04364 11.4731 8.09457 11.489 8.14061C11.6579 8.63036 11.6995 9.32093 11.5863 9.84266C11.5831 9.85726 11.6011 9.86682 11.6116 9.85615L13.1728 8.2628C14.1702 7.24495 14.1617 5.58774 13.154 4.58013C12.1257 3.55183 10.4515 3.56039 9.43367 4.59912L7.834 6.23166C7.83188 6.23389 7.82988 6.23616 7.82774 6.23835Z"
        fill="white"
      />
      <path
        d="M10.7498 10.4598C10.7497 10.4599 10.7497 10.46 10.7497 10.46C10.7507 10.4596 10.7516 10.4592 10.7526 10.4588C11.0712 9.87619 11.134 9.20803 10.9846 8.55669L10.984 8.55738L10.9832 8.55706C10.8414 7.97694 10.4524 7.40087 9.92399 7.04619C9.87853 7.01568 9.80593 7.01921 9.76336 7.05363C9.49571 7.27003 9.23373 7.54752 9.06086 7.90164C9.03371 7.95723 9.05404 8.02401 9.10754 8.05507C9.30817 8.17153 9.48935 8.34204 9.61065 8.56842L9.61084 8.56828C9.70538 8.7282 9.79854 9.03163 9.7382 9.35765C9.73816 9.35765 9.7381 9.35765 9.73807 9.35765C9.68177 9.7899 9.24523 10.1864 8.89821 10.5437L8.89838 10.5438C8.63423 10.8139 7.96273 11.4984 7.69385 11.7731C7.21124 12.2656 6.41337 12.2738 5.9208 11.7912C5.42823 11.3086 5.42009 10.5108 5.9027 10.0183L6.63843 9.26174C6.67178 9.22745 6.68311 9.17762 6.66828 9.13215C6.50492 8.63095 6.46016 7.95595 6.56328 7.4348C6.56615 7.42027 6.54828 7.411 6.53791 7.42158L5.00014 8.99095C3.99256 10.0193 4.0011 11.6935 5.01915 12.7114C6.04747 13.7189 7.71318 13.7018 8.72072 12.6736C9.07074 12.2821 10.5691 10.8949 10.7498 10.4598Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default SocialLinkIcon;

const SvgIcon = styled.svg`
  ${({ theme, theme: { convertPx, isRTL, colors, color } }) => css`
    width: ${convertPx(24)};
    height: ${convertPx(24)};

    margin-right: ${convertPx(isRTL ? 0 : 5)};
    margin-left: ${convertPx(isRTL ? 5 : 0)};
    ${isRTL &&
    css`
      margin-left: ${convertPx(5)};
      margin-right: 0;
    `}

    rect {
      fill: ${colors[color]};
    }

    path {
      fill: ${theme.accentTitleColors[color]};
    }

    ${color &&
    color === 'budapestGirlBlue' &&
    css`
      path {
        fill: ${theme.accentTitleColors['budapestGirlBlue']};
      }
      rect {
        fill: transparent;
      }
    `}
  `}
`;
