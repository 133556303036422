import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Label from '/imports/core/ui/atoms/Label.js';
import { getInputName } from '/imports/generator/api/helpers';
import useTracking from 'imports/core/hooks/useTracking';

const Switch = (props) => {
  const { checked, label, isCoverLetter = false, isReference = false, language, name, variables, children } = props;
  const { trackEvent } = useTracking();
  const inputRef = useRef();

  const onChange = useCallback(() => {
    if (getInputName(name, variables) == 'showTagUI' && inputRef.current != !checked) {
      trackEvent('exp_skills_tag_switch_button', {
        value: !checked,
      });
      inputRef.current = !checked;
    }
    props.onChange({ target: { value: !checked } });
  }, [checked]);

  const handleChange = (isSwitch) => () => {
    if (isSwitch) return onChange();
    if (isCoverLetter) return;
    onChange();
  };
  return (
    <SwitchCont checked={checked} onClick={handleChange(false)} isReference={isReference} isCoverLetter={isCoverLetter}>
      <div>
        <SwitchItem
          checked={checked}
          onClick={handleChange(true)}
          isCoverLetter={isCoverLetter}
          name={`interactive-element-${getInputName(name, variables)}`}
        />
      </div>
      <div>
        <StyledLabel language={language} isCoverLetter={isCoverLetter}>
          {label ? label : children}
        </StyledLabel>
      </div>
    </SwitchCont>
  );
};

Switch.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.node,
  isCoverLetter: PropTypes.bool,
  trackEvent: PropTypes.func,
  isReference: PropTypes.bool,
  language: PropTypes.string,
  variables: PropTypes.object,
  name: PropTypes.string,
};

const SwitchItem = styled.div`
  position: relative;
  width: 35px;
  height: 20px;
  border-radius: 100px;
  background-color: #bfc5d6;
  margin-right: 10px;
  ${({ isCoverLetter }) =>
    isCoverLetter &&
    css`
      cursor: pointer;
    `}
  ${({ theme }) =>
    theme.max('xs')`
    background-color: #e6e6ff;
  `}

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    transform: translate(2px, 0);
    background-color: white;
    height: 16px;
    width: 16px;
    border-radius: 100px;
  }
  ${({ checked }) =>
    checked &&
    css`
      background-color: #1688fe !important;
      &:after {
        transform: translate(17px, 0);
      }
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: ltr;
      margin-right: 0;
      margin-left: 10px;
    `}
`;

const SwitchCont = styled.div`
  display: flex;
  margin-top: 15px;
  cursor: pointer;

  ${({ isCoverLetter }) =>
    isCoverLetter &&
    css`
      cursor: default;
    `}

  > div {
    position: relative;
    &:nth-child(1) {
      flex-shrink: 0;
    }
  }

  ${({ checked, theme }) =>
    !checked &&
    css`
      &:hover > div > div {
        background-color: #b8bfd1;
        ${theme.max('xs')`
          background-color: #e6e6ff;
        `}
      }
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const StyledLabel = styled(Label)`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 13px;
  color: #7171a6;
  cursor: pointer;
  ${({ isCoverLetter }) =>
    isCoverLetter &&
    css`
      cursor: default;
      font-family: ${({ theme }) => theme.font.family.websiteRegular};
      font-size: 16px;
      color: #98a1b3;
      margin-left: 2px;
    `}
`;

export default Switch;
