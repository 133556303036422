import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import { getArabicDefaultFont } from '/imports/pdf/core/api/helpers';
import useIntl from '/imports/core/api/useIntl';

import { DEFAULT_TEMPLATE_FONTS, DEFAULT_TEMPLATE_FONTS_ARABIC } from '/imports/pdf/core/api/constants';
import { FONT_FAMILY } from '/styles/pdf-fonts/constants';

export const getFontFamilies = (template, headingFont, contentFont) => {
  const heading = headingFont || DEFAULT_TEMPLATE_FONTS[template]?.headingFont || 'raleway';
  const content = contentFont || DEFAULT_TEMPLATE_FONTS[template]?.contentFont || 'raleway';
  return [FONT_FAMILY[heading], FONT_FAMILY[content]];
};

export const getTemplateFonts = (
  template,
  isArabicLanguage,
  currentHeadingFont,
  currentContentFont,
  currentArabicHeadingFont,
  currentArabicContentFont,
  isCoverLetter = false,
) => {
  const arabicDefaultFont = isArabicLanguage ? getArabicDefaultFont() : DEFAULT_TEMPLATE_FONTS_ARABIC;
  return {
    headingFont: (!isCoverLetter && currentHeadingFont) || DEFAULT_TEMPLATE_FONTS[template]?.headingFont,
    arabicHeadingFont: (!isCoverLetter && currentArabicHeadingFont) || arabicDefaultFont.headingFont,
    contentFont: (!isCoverLetter && currentContentFont) || DEFAULT_TEMPLATE_FONTS[template]?.contentFont,
    arabicContentFont: (!isCoverLetter && currentArabicContentFont) || arabicDefaultFont.contentFont,
  };
};

/**
 * tell if a text is arabic
 * @param {string}
 * @return {boolean}
 */

export const isArabic = (text) => {
  const { locale } = useIntl();
  const activeVar = locale === 'ar';
  return activeVar;
};

/**
 * Remove decimal if digits after decimal point are 00
 * @param {string | number} num
 * @param {number} numberOfDigits defaultValue = 2
 * @param {string} currency
 * @returns {number}
 */
export const fixDecimal = (num, numberOfDigits = 2, currency = '') => {
  if (!isNaN(Number(num)) && num % 1 !== 0) {
    num = Number(num);
    const numParts = num.toFixed(numberOfDigits).toString().split(/\.|,/);
    if (numParts[1] === '00') return numParts[1];
    return numParts.join(currency === 'EUR' ? ',' : '.');
  }
  return num;
};

/**
 * Separate currency and digits
 * @param {string} input - The input string containing both currency symbol and digits.
 */
export const separateCurrencyAndDigits = (input) => {
  // Remove non-digit characters and convert to a number
  const amount = fixDecimal(input.replace(/[^\d.,]/g, ''));

  // Extract the currency symbol
  const currencySymbol = input.replace(/[\d.,]/g, '');

  // Return an object with separated values
  return {
    amount,
    currency: currencySymbol,
  };
};
