import { getCountry, getCountryName } from './utils';
import qs from 'qs';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';

import env from '/env';
import {
  CLOSE_PRICING_COUNTRY_BROWSER_ONLY_NEW_BROWSER,
  CONTROL_BROSWER_RUN_MOBILE,
  NEW_PRICE_FOR_BROWSER_AND_RUN_MOBILE,
  NEW_PRICE_ON_BROWSWER_CONTROL_MOBILE,
  NEW_PRICING_FOR_BOTH,
} from '/imports/checkout/api/constants';

/**
 * Gets the billing plans for a given [currency].
 *
 * @param {string} currency
 */
export async function getPlans(currency) {
  const queryParams = qs.stringify({ currency });
  let url = `${env.BILLING_ENDPOINT}/v2/plans?${queryParams}`;
  const res = await fetch(url);
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(text);
  }
  return res.json();
}

//get solidgate signature
export const getSignature = async (intent, token, domain) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...intent }),
  };
  let url = `${env.BILLING_ENDPOINT}/generate-solid-signature?domain=${domain}`;
  const res = await fetch(url, options);
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(text);
  }
  return res.json();
};

export const updateUserDomain = async (domain, token) => {
  const queryParams = qs.stringify({ domain });
  let url = `${env.BILLING_ENDPOINT}/update-user-domain?${queryParams}`;
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(url, options);
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(text);
  }
  return true;
};

export const getTrustPilotRules = async () => {
  let url = `${env.BILLING_ENDPOINT}/load-balancing-trust-pilots`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const res = await fetch(url, options);
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(text);
  }
  return res.json();
};

export const getTrustPilotRulesException = async () => {
  let url = `${env.BILLING_ENDPOINT}/trust-pilot-balancer-exceptions`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const res = await fetch(url, options);
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(text);
  }
  return res.json();
};

export const getCheckoutSession = async ({ returnUrl, coupen, priceId, plan, ...rest }) => {
  const country = getCountryName();
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      coupen: coupen,
      priceId: priceId,
      return_url: returnUrl,
      plan: plan,
      country,
      builderUserId: rest?.builderUserId,
    }),
  };
  let url = `${env.BILLING_ENDPOINT}/create-stripe-checkout-session`;
  const res = await fetch(url, options);
  if (res.status !== 202) {
    const text = await res.text();
    throw new Error(text);
  }
  return res.json();
};

export const changeUserRole = async (domain, billingVersion, token) => {
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  let url = `${env.BILLING_ENDPOINT}/change-user-role?domain=${domain}&billingVersion=${billingVersion}`;
  const res = await fetch(url, options);
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(text);
  }
  return res.json();
};

export const reviewClients = async (data, token) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  let url = `${env.BASE_API}/agent/resume-clients`;
  const res = await fetch(url, options);
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(text);
  }
  return res.json();
};

export const writingPaymentSuccess = async (data, token) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  let url = `${env.BILLING_ENDPOINT}/resume-writing-payment-success`;
  const res = await fetch(url, options);
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(text);
  }
  return res.json();
};

export const getLBRules = async (domain, countryCode) => {
  let url = `${env.BILLING_ENDPOINT}/load-balancer-rules?domain=${domain}&countryCode=${countryCode}`;
  const options = {
    method: 'GET',
  };
  const res = await fetch(url, options);
  if (res.status !== 200) {
    const text = await res.text();
    throw new Error(text);
  }
  return res.json();
};

export const getStripeCheckoutProduct = async () => {
  let url = `${env.BILLING_ENDPOINT}/get-checkout-stripe-plan`;
  const res = await fetch(url);
  return res.json();
};

export const isNewPricingVariantExperiment3 = (width) => {
  const country = getCountry();
  if (width <= 450 && CLOSE_PRICING_COUNTRY_BROWSER_ONLY_NEW_BROWSER.includes(country)) return true;
  if (NEW_PRICING_FOR_BOTH.includes(country)) return true;
  if (
    width >= 450 &&
    (NEW_PRICE_FOR_BROWSER_AND_RUN_MOBILE.includes(country) || NEW_PRICE_ON_BROWSWER_CONTROL_MOBILE.includes(country))
  )
    return true;
  if (width >= 450 && CONTROL_BROSWER_RUN_MOBILE.includes(country)) return false;
  return marvelEmitter.getActiveVariant('exp_initial_pricing') === 'new_pricing';
};

export const disabledExpSolidCtaLocale = ['en', 'es', 'tr', 'pt-br', 'ro', 'ru', 'cs', 'lv', 'ar', 'lt'];
