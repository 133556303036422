import React, { useState, useEffect } from 'react';
import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import useBilling from '/imports/checkout/api/useBilling';
import {
  CREATE_STRIPE_PAYMENT_INTENT,
  CREATE_STRIPE_SUBSCRIPTION_SCA,
} from 'imports/checkout/api/apollo/client/mutations';
import { useMutation } from 'react-apollo';
import intlHook from 'imports/core/api/useIntl';
import styled, { css } from 'styled-components';
import Flex from 'imports/core/ui/atoms/Flex';
import useTracking from 'imports/core/hooks/useTracking';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import { getCountry } from 'imports/checkout/api/utils';
import DownArrowIcon from 'imports/job-tracking/ui/assets/DownArrowIcon';
import AccountContext, { useAccount } from 'imports/core/api/accounts/accountContext';

countries.registerLocale(enLocale);

const StripeFormV3 = ({ onSuccess, ctaTitle }) => {
  const { currentPlan, showTerms, client } = useBilling();
  const { t } = intlHook();
  const { trackEvent } = useTracking();
  const stripe = useStripe();
  const elements = useElements();
  const { currentUser } = useAccount(AccountContext);
  const [cardNumberError, setCardNumberError] = useState('');
  const [expiryDateError, setExpiryDateError] = useState('');
  const [cvcError, setCvcError] = useState('');
  const [isCardNumberValid, setIsCardNumberValid] = useState(false);
  const [isExpiryDateValid, setIsExpiryDateValid] = useState(false);
  const [isCvcValid, setIsCvcValid] = useState(false);
  const [country, setCountry] = useState('');
  const [tokenizeError, setTokenizeError] = useState(null);
  const [countryError, setCountryError] = useState('');
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createStripePaymentIntent] = useMutation(CREATE_STRIPE_PAYMENT_INTENT, { client });
  const [createStripeSubscriptionSCA] = useMutation(CREATE_STRIPE_SUBSCRIPTION_SCA, { client });

  const countryList = countries.getNames('en', { select: 'official' });

  useEffect(() => {
    const country = getCountry();
    if (country) {
      setCountry(country);
      setCountryError(false);
    }
  }, []);

  const handleElementChange = (data) => {
    switch (data.elementType) {
      case 'cardNumber':
        setCardNumberError(data.error ? data.error.message : '');
        setIsCardNumberValid(data.complete);
        setIsFormFilled(isExpiryDateValid && isCvcValid && data?.complete && !countryError);
        break;
      case 'cardExpiry':
        setExpiryDateError(data.error ? data.error.message : '');
        setIsExpiryDateValid(data.complete);
        setIsFormFilled(isCvcValid && isCardNumberValid && data?.complete && !countryError);
        break;
      case 'cardCvc':
        setCvcError(data.error ? data.error.message : '');
        setIsCvcValid(data.complete);
        setIsFormFilled(isExpiryDateValid && isCardNumberValid && data?.complete && !countryError);
        break;
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setCountry(selectedCountry);
    setCountryError(selectedCountry ? false : true);
    setIsFormFilled(isCardNumberValid && isExpiryDateValid && isCvcValid && !!selectedCountry);
  };

  const onSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);
      trackEvent('Payment Initiated');
      const {
        data: { createStripePaymentIntent: paymentIntent },
      } = await createStripePaymentIntent({
        variables: {
          name: `${currentUser.firstName} ${currentUser.lastName}`,
          amount: currentPlan.trialAmount,
          currency: currentPlan.currency,
        },
      });
      const { error, paymentIntent: payment } = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            address: {
              country,
            },
          },
        },
      });
      if (error) {
        setTokenizeError(error.message);
        setIsSubmitting(false);
        return;
      }
      if (payment.status === 'succeeded') {
        await createStripeSubscriptionSCA({
          variables: {
            planId: currentPlan.id,
          },
        });
      }
      const paymentMethodTypes = payment?.payment_method_types;
      const paymentMethodType = paymentMethodTypes && paymentMethodTypes.length > 0 ? paymentMethodTypes[0] : null;

      onSuccess(paymentMethodType);
    } catch (error) {
      console.log('Catch Error', error);
      setIsFormFilled(false);
      setIsSubmitting(false);
      setTokenizeError(error.message || 'Something went wrong, please try again later');
    }
  };

  return (
    <MainFlex direction="column" fullWidth>
      <FormWrapper direction="column" alignItems="center" justifyContent="center" fullWidth>
        <InputWrapper direction="column" fullWidth>
          <InputLabel>{t('payment_form_card_number')}</InputLabel>
          <StyledCardElement
            onChange={handleElementChange}
            options={{
              placeholder: '4242 4242 4242 4242',
              showIcon: true,
            }}
          />
          {cardNumberError && <ErrorText>{cardNumberError}</ErrorText>}
        </InputWrapper>
        <MergeWrap fullWidth>
          <InputWrapper direction="column" fullWidth>
            <InputLabel>{t('payment_form_expiry_date')}</InputLabel>
            <StyledExpiryElement
              onChange={handleElementChange}
              options={{
                placeholder: t('date_placeholder'),
              }}
            />
            {expiryDateError && <ErrorText>{expiryDateError}</ErrorText>}
          </InputWrapper>
          <InputWrapper direction="column" fullWidth>
            <InputLabel>{t('solid_card_cvv_placeholder')}</InputLabel>
            <StyledCvvElement
              onChange={handleElementChange}
              options={{
                placeholder: t('solid_card_cvv_placeholder'),
              }}
            />
            {cvcError && <ErrorText>{cvcError}</ErrorText>}
          </InputWrapper>
        </MergeWrap>
        <InputWrapper direction="column" fullWidth>
          <InputLabel>{t('generator.form.country')}</InputLabel>
          <SelectWrap $fullWidth>
            <Select onChange={handleCountryChange} value={country}>
              <option value="" disabled id="disabled">
                {t('select_country')}
              </option>
              {Object.entries(countryList).map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </Select>
            <SvgWrapper>
              <DownArrowIcon />
            </SvgWrapper>
          </SelectWrap>
          {countryError && <ErrorText>{countryError}</ErrorText>}
        </InputWrapper>
      </FormWrapper>
      {tokenizeError && <ErrorText>{tokenizeError}</ErrorText>}
      <Button
        fullWidth
        alignItems="center"
        justifyContent="center"
        disabled={!isFormFilled || isSubmitting}
        onClick={onSubmit}
      >
        <div>{isSubmitting ? `${t('payment.loading')}...` : ctaTitle}</div>
      </Button>
    </MainFlex>
  );
};

const SelectWrap = styled(Flex)`
  position: relative;
`;

const SvgWrapper = styled(Flex)`
  position: absolute;
  top: 45%;
  right: 12px;
  svg > path {
    fill: #6d6e78;
  }
`;

const MainFlex = styled(Flex)`
  gap: 32px;
  margin-bottom: 40px;
`;

const Button = styled(Flex)`
  padding: 14px 12px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #fc8302;
  color: #fff;
  gap: 12px;
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  &:hover {
    background-color: #ffa726;
  }
  &:active {
    background-color: #d65b00;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: #f7c797;
    `}
`;

const Input = styled.input`
  display: flex;
  width: 100%;
  padding: 14px 0px 14px 12px;
  border-radius: 8px;
  border: 1px solid #e3e3e4;
  background: #fff;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 14px;
  color: #01132c;
  font-weight: 500;
  line-height: 24px;
  &::placeholder {
    color: #797979 !important;
  }
`;

const StyledCvvElement = styled(CardCvcElement)`
  display: flex;
  width: 100%;
  padding: 14px 0px 14px 12px;
  border-radius: 8px;
  border: 1px solid #e3e3e4;
  background: #fff;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 14px;
  color: #01132c;
  line-height: 24px;

  &::placeholder {
    color: #d5d8da !important;
  }
  .__PrivateStripeElement {
    width: 100% !important;
  }
`;

const StyledExpiryElement = styled(CardExpiryElement)`
  display: flex;
  width: 100%;
  padding: 14px 0px 14px 12px;
  border-radius: 8px;
  border: 1px solid #e3e3e4;
  background: #fff;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 14px;
  color: #01132c;
  line-height: 24px;

  &::placeholder {
    color: #d5d8da;
  }
  .__PrivateStripeElement {
    width: 100% !important;
  }
`;

const MergeWrap = styled(Flex)`
  gap: 24px;
`;

const ErrorText = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 12px;
  color: red;
  margin-top: 4px;
`;

const StyledCardElement = styled(CardNumberElement)`
  display: flex;
  width: 100%;
  padding: 14px 0px 14px 12px;
  border-radius: 8px;
  border: 1px solid #e3e3e4;
  background: #fff;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 14px;
  color: #01132c;
  line-height: 24px;

  &::placeholder {
    color: #d5d8da !important;
  }
  .__PrivateStripeElement {
    width: 100% !important;
  }
`;

const Select = styled.select`
  display: flex;
  position: relative;
  width: 100%;
  padding: 14px 28px 14px 28px;
  border-radius: 8px;
  border: 1px solid #e3e3e4;
  background: #fff;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 14px;
  color: #01132c;
  font-weight: 500;
  line-height: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  > option {
    :disabled {
      coxlor: #d5d8da !important;
    }
  }
`;

const InputLabel = styled.label`
  color: #01132c;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 14px;
  line-height: 24px;
`;

const InputWrapper = styled(Flex)`
  gap: 4px;
  position: relative;
  #stripe-card-element {
    width: 100% !important;
  }
`;

const FormWrapper = styled(Flex)`
  gap: 16px;
`;

export default StripeFormV3;
