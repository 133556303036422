import React from 'react';
import SocialLinkInputCheckBox from '/imports/core/ui/atoms/SocialLinkInputCheckBox';
import Autosave from '/imports/core/ui/components/Autosave';

const SocialLinkCheckboxAutoSave = (props) => {
  const { className, name, label, value, isCoverLetter, variables, language, resume } = props;

  return (
    <Autosave {...props}>
      {({ onChange }) => (
        <SocialLinkInputCheckBox
          language={language}
          className={className}
          name={name}
          onChange={onChange}
          checked={value}
          label={label}
          isCoverLetter={isCoverLetter}
          variables={variables}
          resume={resume}
        />
      )}
    </Autosave>
  );
};

export default SocialLinkCheckboxAutoSave;
