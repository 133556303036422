import PropTypes from 'prop-types';
import Switch from './Switch';

import Autosave from '/imports/core/ui/components/Autosave';

const SwitchAutosave = (props) => {
  const { className, name, children, label, value, isCoverLetter, variables, language } = props;
  return (
    <Autosave {...props}>
      {({ onChange }) => (
        <Switch
          language={language}
          className={className}
          name={name}
          onChange={onChange}
          checked={value}
          label={label}
          isCoverLetter={isCoverLetter}
          variables={variables}
        >
          {children}
        </Switch>
      )}
    </Autosave>
  );
};

SwitchAutosave.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isCoverLetter: PropTypes.bool,
  language: PropTypes.string,
  variables: PropTypes.object,
};

export default SwitchAutosave;
