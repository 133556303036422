import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import PDFViewer from '/imports/pdf/core/ui/components/PDFViewer';
import PreviewHeader from '/imports/generator/ui/components/PreviewHeader';
import useTracking from '/imports/core/hooks/useTracking';

const PreviewWrapper = (props) => {
  const [width, setWidth] = useState(0);
  const { trackEvent } = useTracking();
  const previewRef = useRef();
  const { setPageState, getResume, loading, updateImmue, loadingState } = props;

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    updateWidth();
    trackEvent('preview_view');
    return window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    updateWidth();
  }, [loadingState]);

  const updateWidth = () => {
    if (!previewRef.current) return;
    const width = previewRef.current.offsetWidth;
    setWidth(width);
  };

  return (
    <Page>
      <PreviewHeader source={getResume} loading={loading} updateImmue={updateImmue} />
      <PreviewContent>
        <Preview ref={previewRef}>
          <PDFViewer
            source={getResume}
            updateCount={getResume.updatesCount}
            setPageState={setPageState}
            width={width}
            noDebounce
            updateImmue={updateImmue}
          />
        </Preview>
      </PreviewContent>
    </Page>
  );
};

const Page = styled.section`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  pointer-events: auto;
  transform: translateY(0px);
  flex-flow: column nowrap;
`;

const PreviewContent = styled.div`
  background-color: #e5e4ea;
  height: calc(100vh - 70px);
  flex: 1 0 0px;
  padding: 20px;
  overflow-y: auto;

  ${({ theme }) => theme.max('sm')`
    padding: 0px 20px;
  `}

  &::-webkit-scrollbar {
    width: 14px;
    height: 292px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    scroll-padding-top: 10px;
    background-color: #131518;
  }
`;

const Preview = styled.div`
  max-width: 928px;
  overflow: hidden;
  height: ${({ height }) => height}px;
  transform: translateY(0px);
  opacity: 1;
  margin: 60px auto;
  background: white;
  border-radius: ${({ theme }) => theme.general.borderRadius};
  ${({ theme }) => theme.max('md')`
    margin: 44px auto;
  `}
  ${({ theme }) => theme.max('sm')`
    margin: 20px auto;
  `}
`;

export default PreviewWrapper;
