import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import Address from '/imports/core/ui/components/Address';
import CTA from '/imports/landing/ui/components/CTA';
import { footerLinks } from '/imports/homepage/api/constants';
import Link from '/components/Link';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';

const Footer = ({ ctaClick, userIdentified, breakpoint }) => {
  const { t, locale } = useIntl();
  const { host } = useResponsive();
  const [showCookiesSettings, setShowCookiesSettings] = useState(false);

  const links = footerLinks(host, locale);
  useEffect(() => {
    setTimeout(() => {
      if (window?.axeptioSDK) {
        setShowCookiesSettings(true);
      }
    }, 3000);
  }, []);
  return (
    <Container>
      <StyledUl>
        {links.map((item, index) => (
          <StyledLi key={index}>
            <Link href={item.href}>{item.text ? item.text : t(item.slug)}</Link>
          </StyledLi>
        ))}
        <StyledLi>
          <Link href="/login" passHref>
            {t('landing.login')}
          </Link>
        </StyledLi>
        {locale === 'de' && (
          <StyledLi>
            <Link href="/legal-notice" passHref>
              {t('footer.legal_notice')}
            </Link>
          </StyledLi>
        )}
        {showCookiesSettings && (
          <StyledLi>
            <Link href="javascript:openAxeptioCookies()">{t('footer.cookies_settings')}</Link>
          </StyledLi>
        )}
      </StyledUl>
      <Address />
      {/*<StyledCTA>
        <CTA landing isHomepage onClick={ctaClick} userIdentified={userIdentified} isBottom breakpoint={breakpoint} />
        </StyledCTA>*/}
    </Container>
  );
};

const StyledUl = styled.ul`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  align-items: start;
  flex-wrap: wrap;
  padding: 14px 20px 8px;
  ${({ theme }) =>
    theme.max('sm')`
      padding-top: 0;
      justify-content: space-around;
  `}
`;

const StyledLi = styled.li`
  ${({ theme: { isRTL } }) => css`
    flex: 0 0 auto;
    padding: 0 10px;
    text-align: center;
    margin: 8px 0;
    list-style: none;
    ${({ theme }) =>
      theme.max('xs')`
      margin: 8px;
  `}
    ${({ theme }) =>
      theme.max('sm')`
       width: 45%;
       flex: 1 0 auto;
  `}
  a {
      display: block;
      color: #6d6e71;
      padding-bottom: 5px;
      max-width: 100%;
      width: 100%;
      border-bottom: 1px solid #a7a9ac;
      margin: 0;
      font-size: 12px;
      line-height: 1;
      letter-spacing: normal;
      text-decoration: none;
      opacity: 0.6;
      font-family: ${({ theme }) => theme.font.family.websiteMedium};
      &:hover {
        transition: all 0.2s;
        color: rgb(243, 113, 1);
        opacity: 0.8;
      }
    }
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  box-shadow: 10px 0 50px 0 rgba(0, 0, 0, 0.15);
  background-color: rgb(255, 255, 255);
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  position: relative;
  ${({ theme }) =>
    theme.max('sm')`
        box-shadow: none;
        width: 100%;
  `}
`;

const StyledCTA = styled.div`
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;

  a {
    width: 100%;
    margin: 0;
    border-radius: 0;
  }

  span {
    width: 100%;
    border-radius: 0;
  }

  ${({ theme }) =>
    theme.min('sm')`
      display: none;
    `}
`;

export default Footer;
