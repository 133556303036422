const DownArrowIcon = () => (
  <svg width="14px" height="8px">
    <path
      fillRule="evenodd"
      fill="rgb(230, 235, 239)"
      d="M13.581,0.412 C13.120,-0.040 12.420,-0.107 11.889,0.212 L8.475,3.628 C8.447,3.655 8.419,3.681 8.391,3.695 C8.014,4.040 7.510,4.226 6.993,4.226 C6.475,4.226 5.985,4.040 5.594,3.695 C5.566,3.681 5.538,3.655 5.510,3.628 L2.068,0.186 C1.537,-0.107 0.865,-0.027 0.404,0.412 C-0.142,0.930 -0.142,1.781 0.404,2.312 L5.510,7.429 C5.915,7.802 6.433,8.014 6.993,8.014 C7.552,8.014 8.084,7.802 8.475,7.429 L13.581,2.312 C14.127,1.781 14.127,0.930 13.581,0.412 Z"
    />
  </svg>
);

export default DownArrowIcon;
