import React, { useContext, createContext, useReducer, useEffect } from 'react';

import Reducer from '/imports/job-tracking/api/reducers/boards.reducer';

const BoardContext = createContext();
export const Provider = BoardContext.Provider;
export const BoardConsumer = BoardContext.Consumer;

const BoardsProvider = ({
  children,
  boards = [],
  boardsList = [],
  activeBoard = {},
  activeJobDetail = null,
  otherProps,
}) => {
  const [state, dispatch] = useReducer(Reducer, {
    boards,
    boardAddModal: false,
    taskListActiveJobId: null,
    boardsList,
    activeBoard,
    loading: true,
    activeJobDetail,
  });
  const handleUpdateState = (name, payload) => {
    dispatch({ type: 'UPDATE_BOARD', name, payload });
  };
  useEffect(() => {
    if (boards && boards.length > 0) dispatch({ type: 'SET_BOARD', payload: boards });
  }, [boards]);
  return (
    <Provider
      value={{
        state,
        dispatch,
        ...otherProps,
        handleUpdateState,
      }}
    >
      {children}
    </Provider>
  );
};

export const withBoards = () => useContext(BoardContext);

export default BoardsProvider;
