import styled, { css } from 'styled-components';

import Heading from '/imports/core/ui/atoms/Heading';

const WizardHeader = styled((p) => <Heading as="h4" {...p} />)`
  margin-top: 0.8em;
  margin-bottom: 0.4em;
  font-size: 32px;
  line-height: 1.13;
  letter-spacing: normal;

  ${({ theme }) =>
    theme.designV2 &&
    css`
      font-size: 34px;
      flex-grow: 1;
      font-family: Gilroy;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
    `}

  font-family: ${({ theme }) => theme.font.family.websiteBold};

  color: #33334f !important;
  ${(p) =>
    p.intro &&
    css`
      font-size: 31px;
    `}
  ${(p) =>
    p.red &&
    css`
      & span {
        color: ${(p) => p.theme.colors.danger};
      }
    `}
  ${(p) =>
    p.center &&
    css`
      text-align: center;
    `}
  
  span {
    color: #1688fe;
  }

  ${({ theme }) =>
    theme.max('xs')`
      font-size: 24px;
      line-height: 1.25;
      padding: 0 ${(props) => (props.padded ? '18' : '5')}px;
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

WizardHeader.displayName = 'WizardHeader';

export default WizardHeader;
