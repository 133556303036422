import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import AISuggestionModal from '/imports/generator/ui/components/AISuggestionModal';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import nookies from 'nookies';
import styled, { css } from 'styled-components';

import { createChatCompletion, createCompletion } from '/imports/generator/api/openaiApi';
import Flex from '/imports/core/ui/atoms/Flex';
import {
  isDraftContentEmpty,
  getLanguageNameByCode,
  buildAISuggestionPrompt,
  buildAIRephrasingPrompt,
  isDraftContentOver30,
  getAISuggestionVariant,
} from '/lib/helpers';
import AICTAIcon from '/imports/generator/ui/atoms/AICTAIcon';
import { replaceString } from '/imports/core/ui/helpers';
import { RTLLanguages } from '/imports/generator/api/constants';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import useWindowSize from '/imports/core/api/useWindowSize';
import { useResponsive } from 'imports/core/api/responsiveContext';

const AISuggestion = ({
  container,
  source,
  item,
  type,
  updateSelectedAISuggestion,
  updateIsSelectedBullet,
  showDescription = true,
}) => {
  const { isGeneratorMobileView, isMobile } = useResponsive();
  const { token } = nookies.get({});
  const { trackEvent } = useTracking();
  const { locale, t } = useIntl();
  const { width } = useWindowSize();
  const [noPreview, setNoPreview] = useState(width <= 800);
  const isRTL = RTLLanguages.includes(locale);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [suggestions, setSuggestions] = useState([]);
  const [rephrasing, setRephrasing] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [withRephrasing, setWithRephrasing] = useState(false);
  const [ctaNextLine, setCtaNextLine] = useState(false);
  const hasCharacters = useMemo(() => isDraftContentOver30(item?.fields?.description), [item?.fields?.description]);
  const ctaTextVariant = marvelEmitter.getActiveVariant('ai_suggestion_cta');
  const isAIV2 = ['with_ai_suggestion_v2'].includes(getAISuggestionVariant(isGeneratorMobileView, locale));
  const modelVariant = marvelEmitter.getActiveVariant('ai_suggestion_openai_model');
  const tooltipRef = useRef(null);
  const buttonRef = useRef(null);

  const isDisabled = useCallback(() => {
    switch (type) {
      case 'PROFESSIONAL_SUMMARY':
        return (
          isDraftContentEmpty(item?.fields?.description) &&
          !source.blocks.find((block) => block.type === 'EMPLOYMENT')?.items?.some((emp) => emp?.fields?.title)
        );
      case 'EMPLOYMENT':
        return !item?.fields?.title && isDraftContentEmpty(item?.fields?.description);
      case 'EDUCATION':
        return !item?.fields?.degree && isDraftContentEmpty(item?.fields?.description);
      default:
        return false;
    }
  }, [source]);
  const displayBadge = useCallback(
    (disabled) => {
      const ctaStatus = localStorage.getItem(`AI-SUGGESTION-${type}-USAGE`);
      if (ctaStatus === 'hide_badge') {
        return false;
      }
      if (ctaStatus === 'badge_shown' && disabled) {
        localStorage.setItem(`AI-SUGGESTION-${type}-USAGE`, 'hide_badge');
        return false;
      }
      if (!ctaStatus) {
        if (!disabled && hasCharacters) {
          localStorage.setItem(`AI-SUGGESTION-${type}-USAGE`, 'badge_shown');
          return true;
        }
        return false;
      }
      return true;
    },
    [hasCharacters],
  );

  const disabled = useMemo(() => isDisabled(), [isDisabled]);
  const showBadge = displayBadge(disabled);

  const updateModalPosition = () => {
    if (noPreview) return;
    const containerPosition = container.current?.getBoundingClientRect();
    const newPosition = {
      top: containerPosition?.top - 100,
      left: isRTL ? containerPosition?.right - containerPosition?.width - 420 : containerPosition?.right,
    };
    setModalPosition(newPosition);
  };

  /**
   * Check if the width has changed from the previous call
   * (tooltip position has  yet to be adjusted => to the middle of AI CTA, fixed position)
   * @param  currentPosition current position of tooltip passed to next recursive func call (for comparision with newPosition)
   * @param n to limit max number of recursive call (Default value = 1 : there is no recursive function all)
   **/
  const updateTooltipPosition = (currentPosition = -1, n = 1) => {
    const buttonPosition = buttonRef.current?.getBoundingClientRect();
    const tooltipWrapper = tooltipRef.current;

    if (tooltipWrapper) {
      tooltipWrapper.style.top = `${buttonPosition?.top - tooltipWrapper.clientHeight - 5}px`;
      tooltipWrapper.style.left = `${
        buttonPosition?.left + (buttonRef.current.clientWidth / 2 - tooltipWrapper.clientWidth / 2)
      }px`;
      const newPosition = tooltipWrapper.getBoundingClientRect().x;
      if (newPosition !== currentPosition && n > 1) {
        updateTooltipPosition(newPosition, n - 1);
      }
    }
  };

  const updatePositions = () => {
    updateModalPosition();
    updateTooltipPosition();
  };
  const extractContent = (inputString) => {
    const regex = /"([^"]+)"/g;
    const matches = inputString.match(regex);
    const result = [];

    if (matches) {
      // Extract content inside double quotes
      for (const match of matches) {
        const content = match.slice(1, -1); // Remove the double quotes
        result.push(content);
      }
    } else {
      // If no content inside double quotes, add the entire string
      result.push(inputString);
    }

    return result;
  };

  const getOpenAIModel = (prompt) => {
    if (isAIV2) {
      switch (modelVariant) {
        case 'gpt-4o':
          return {
            model: 'gpt-4o',
            messages: [{ role: 'user', content: prompt }],
          };
        case 'gpt-4o-mini':
          return {
            model: 'gpt-4o-mini',
            messages: [{ role: 'user', content: prompt }],
          };
        case 'control':
        default:
          return {
            model: 'gpt-4-1106-preview', //gpt-4-turbo
            messages: [{ role: 'user', content: prompt }],
            temperature: 0.3,
          };
      }
    }
    return {
      model: 'gpt-4',
      messages: [{ role: 'user', content: prompt }],
    };
  };
  const openAIApiCall = async (prompt) => {
    const modelParameter = getOpenAIModel(prompt);
    const chatCompletionModels = [
      'gpt-4',
      'gpt-3.5-turbo',
      'gpt-3.5-turbo-1106',
      'gpt-4-1106-preview',
      'gpt-4o',
      'gpt-4o-mini',
    ];
    const isChatCompletion = chatCompletionModels.includes(modelParameter.model);
    let completionText = '';
    if (isChatCompletion) {
      const completion = await createChatCompletion(modelParameter, token);
      completionText = completion.choices[0].message.content;
    } else {
      const completion = await createCompletion(modelParameter, token);
      completionText = completion.choices[0].text;
    }
    return completionText;
  };
  const removeSpecialCharacterFromAIOutput = (str) => {
    return str.replaceAll('**', '');
  };
  const fetchAIRephrasing = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const promptLanguageCode = source?.settings?.language || locale;
        const prompt = buildAIRephrasingPrompt(item, type, t, promptLanguageCode);
        const languagePrompt = replaceString(t('prompt_language_setting', promptLanguageCode), {
          RESUME_LANGUAGE: getLanguageNameByCode(promptLanguageCode),
        });
        const finalPrompt = `${prompt}${languagePrompt}`;
        let completionText = await openAIApiCall(finalPrompt);
        completionText = removeSpecialCharacterFromAIOutput(completionText);
        const regex = /•([\s\S]*)/; // regex to find the start of bullet list of the response
        const match = regex.exec(completionText);

        if (match) {
          const bulletFormatted = match[0].replace(/([^\n])•/g, '$1\n•'); // format as list bullet formatted with new line
          const result = bulletFormatted.replace(/\n(?!•)([\s\S]*)$/, ''); // remove unwanted comment from openai if it has  , we only keep the bullet formatted part
          resolve(result);
        } else {
          resolve(completionText.replace(/^\n+/, ''));
        }
      } catch (e) {
        console.log('OPENAI SUGGESTION ERROR:', e);
        resolve('fail');
      }
    });
  };

  const fetchAISuggestion = () => {
    return new Promise(async (resolve, reject) => {
      let completionText = '';
      try {
        const promptLanguageCode = source?.settings?.language || locale;
        const prompt = buildAISuggestionPrompt(source, item, type, t, promptLanguageCode, isAIV2);
        const languagePrompt = replaceString(t('prompt_language_setting', promptLanguageCode), {
          RESUME_LANGUAGE: getLanguageNameByCode(promptLanguageCode),
        });
        const finalPrompt = `${prompt}${languagePrompt}`;
        completionText = await openAIApiCall(finalPrompt);
        completionText = removeSpecialCharacterFromAIOutput(completionText);
        const jsonArrayFormatted = completionText.slice(
          completionText.indexOf('['),
          completionText.lastIndexOf(']') + 1,
        );
        const ans = JSON.parse(jsonArrayFormatted);
        resolve(ans);
      } catch (e) {
        // when open ai response is not formated as a javascript array due to inaccuracy
        const arrayRegex = /\["[^"]*"\]/;
        if (!arrayRegex.test(completionText)) {
          return resolve(extractContent(completionText));
        }
        //Error occured because of json parse (unexpected response from openai API)
        if (e instanceof SyntaxError) {
          // Extract text between [" "] using regex and create array from that
          const arr = [...completionText.matchAll(/\["([^"]+)"\]/g)].map((match) => match[1]);
          return resolve(arr || []);
        }
        trackEvent('ai_optimize_cta_fail');
        console.log('OPENAI SUGGESTION ERROR:', e);
        resolve([]);
      }
    });
  };
  const fetchAll = async () => {
    setOpen(true);
    setWithRephrasing(hasCharacters);
    updateModalPosition();
    if (loading) return;
    setLoading(true);
    try {
      const functions = [fetchAISuggestion()];
      const shouldFetchRephrasing = isAIV2 && hasCharacters;
      if (shouldFetchRephrasing) {
        functions.push(fetchAIRephrasing());
      }
      const results = await Promise.all(functions);
      if (results[0]) {
        if (results[0] && results[0]?.length > 0) {
          trackEvent('ai_suggestions_loaded');
        }
        setSuggestions(results[0]);
      }
      if (shouldFetchRephrasing) {
        setRephrasing(results[1]);
      }
    } catch (e) {
      console.log('OPEN AI FETCH ERROR', e);
    } finally {
      setLoading(false);
    }
  };
  const openAISuggestionsModal = (disabled) => async (e) => {
    e.preventDefault();
    if (disabled) {
      if (noPreview) setShowTooltip(true);
      return;
    }
    let obj = {
      step: type,
    };
    trackEvent('ai_optimize_cta', obj);
    localStorage.setItem(`AI-SUGGESTION-${type}-USAGE`, 'hide_badge');
    fetchAll();
  };

  const renderCTAText = useMemo(() => {
    if (['en', 'fr', 'es'].includes(locale)) {
      return t('generator_ai_suggestion_button_text2');
    } else {
      switch (ctaTextVariant) {
        case 'ai_suggestion_cta_v1':
          return t('generator_ai_suggestion_button_text1');
        case 'ai_suggestion_cta_v2':
          return t('generator_ai_suggestion_button_text2');
        case 'ai_suggestion_cta_v3':
          return t('generator_ai_suggestion_button_text3');
        case 'ai_suggestion_cta_v4':
        default:
          return t('generator_ai_suggestion_button_text4');
      }
    }
  }, [locale, ctaTextVariant]);

  useEffect(() => {
    updateIsSelectedBullet(type !== 'PROFESSIONAL_SUMMARY');
    updatePositions();
    const scrollDiv = document.getElementById('scroll-cont');
    if (scrollDiv) {
      scrollDiv.addEventListener('scroll', updatePositions);
    }
    window.addEventListener('resize', updatePositions);
    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener('scroll', updatePositions);
      }
      window.removeEventListener('resize', updatePositions);
    };
  }, []);

  useEffect(() => {
    if (showTooltip) {
      updateTooltipPosition();
    }
  }, [showTooltip]);

  useEffect(() => {
    const actualCondition = width <= 800;
    if (noPreview !== actualCondition) {
      setNoPreview(actualCondition);
    }
  }, [width]);

  useEffect(() => {
    //Dynamically updates the responsiveness of AI CTA (set with absolite position) based on current cta size and available space
    const handleAICtaResponsiveness = () => {
      const editorComponent = container?.current?.querySelector('[data-draft-buttons]');
      const editorEdgeItem = editorComponent?.lastElementChild;
      const buttonComponent = buttonRef.current;
      const width = window.innerWidth;
      if (editorEdgeItem && buttonComponent) {
        const pos1 = editorEdgeItem.getBoundingClientRect();
        const pos2 = buttonComponent.getBoundingClientRect();
        const isButtonPositionUpdated = buttonComponent?.getAttribute('data-position-updated') == 'true';

        // Check for x-axis overlap on draft editor items
        const withUndoRedo =
          marvelEmitter.getActiveVariant('exp_undoredo_textarea_editor') === 'with_undo_redo' || isMobile;
        const isOverlapping = pos1.x < pos2.x + pos2.width && pos1.x + pos1.width + (withUndoRedo ? 5 : 15) > pos2.x; // 15 is for the padding/margin
        if ((isOverlapping || noPreview) && !isButtonPositionUpdated) {
          buttonComponent.setAttribute('data-position-updated', true);
          setCtaNextLine(true);

          //increase draft wrapper height to let absolute-positioned button to fit to  the bottom
          const draftWrapper = container.current.children[1];
          draftWrapper.style.height = `${draftWrapper.getBoundingClientRect().height + (width <= 1024 ? 30 : 55)}px`;

          //set button postion to the bottom
          const buttonFullWidth = draftWrapper.firstElementChild.getBoundingClientRect().width;
          buttonComponent.style.width = `${buttonFullWidth}px`;
          buttonComponent.style.bottom = `12px`;
          updateTooltipPosition(undefined, 4);
        }
      }
    };

    //449 is the viewport widht where description should be always shown.
    if (container.current && (showDescription || width > 449)) {
      setTimeout(() => {
        handleAICtaResponsiveness();
      }, 0);
    }
  }, [container.current, showDescription]);
  return (
    <>
      <Tooltip ref={tooltipRef} showTooltip={showTooltip && disabled}>
        <ToastText>
          {t(
            type === 'EDUCATION'
              ? 'generator_ai_suggestion_tooltip_text_education'
              : 'generator_ai_suggestion_tooltip_text',
          )}
        </ToastText>
        {showTooltip && disabled && <PointArrow />}
      </Tooltip>
      <AIUpgradeButton
        onClick={openAISuggestionsModal(disabled)}
        disabledStyle={disabled}
        ref={buttonRef}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        ctaNextLine={ctaNextLine}
      >
        {showBadge && (
          <NotificationCircle alignItems="center" justifyContent="center">
            1
          </NotificationCircle>
        )}
        <AIUpgradeButtonFlex alignItems="center">
          <AICTAIcon />
          {renderCTAText}
        </AIUpgradeButtonFlex>
      </AIUpgradeButton>
      <AISuggestionModal
        type={type}
        open={open}
        setOpen={setOpen}
        modalPosition={modalPosition}
        isMobile={noPreview}
        loading={loading}
        setLoading={setLoading}
        suggestions={suggestions}
        rephrasing={rephrasing}
        setRephrasing={setRephrasing}
        updateSelectedAISuggestion={updateSelectedAISuggestion}
        isAIV2={isAIV2}
        withRephrasing={withRephrasing}
      />
    </>
  );
};
export default AISuggestion;

const NotificationCircle = styled(Flex)`
  position: absolute;
  top: -7px;
  left: -7px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 50%;
  background: red;
  color: white;
`;
const Tooltip = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  padding: 12px;
  min-height: auto;
  min-width: auto;
  max-width: 475px;
  border-radius: 4px;
  user-select: none;
  z-index: 9999;
  background-color: var(--grayscale-n800);
  @media (max-width: 800px) {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  ${(p) =>
    !p.showTooltip &&
    css`
      > div {
        color: transparent !important;
      }
      background-color: transparent;
      z-index: -9999;
    `}
`;
const ToastText = styled.div`
  color: var(--light-values-white);
  font-size: 14px;
  line-height: 14px;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
`;
const PointArrow = styled.div`
  position: absolute;
  bottom: -5px;
  left: 50%;
  right: 0;
  margin-right: auto;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--grayscale-n800);
`;
const AIUpgradeButton = styled.button`
  position: absolute;
  right: 25px;
  bottom: 7px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 5px 21px 0px rgba(20, 20, 31, 0.12);

  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 40px;
  padding: 16px 20px;
  display: flex;

  color: #0072e8;
  font-family: Gilroy SemiBold;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 449px) {
    right: 5px;
  }
  ${({ ctaNextLine }) =>
    ctaNextLine &&
    css`
      right: 33px;
      @media (max-width: 449px) {
        right: 10px;
      }
    `}
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      left: 25px;
      right: inherit;
    `}
  &:hover {
    background-color: #fcfcfc;
  }
  &:active {
    background-color: #f5f5f5;
  }
  ${(p) =>
    p.disabledStyle &&
    css`
      cursor: default;
      background: #dbdee9;
      box-shadow: 0px 5px 21px 0px rgba(20, 20, 31, 0.12);
      color: #8d93a5;
      path {
        stroke: #8d93a5;
        fill: #8d93a5;
      }
      &:hover {
        background-color: #dbdee9;
      }
      &:active {
        background-color: #dbdee9;
      }
    `}
`;
const AIUpgradeButtonFlex = styled(Flex)`
  gap: 4px;
`;
